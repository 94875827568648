{
  "title": "Português",
  "startDateCantBeGreaterThanFinalDate": "A data inicial não pode ser maior que a data final!",
  "LoginSuccess": "Logado com sucesso!",
  "Login": "Entrar",
  "signIncompetitionManager": {
    "signIn": "Entrar",
    "welcome": "Seja bem vindo",
    "signInDetails": "Confirme as informações abaixo."
  },
  "InvalidLogin": "Email ou senha inválidos!",
  "search": "Procurar",
  "logout": "Sair",
  "logoutUnable": "Houve um erro ao tentar deslogar!",
  "emailRequired": "Email obrigatório!",
  "passwordRequired": "Senha obrigatória!",
  "emailLabel": "Email",
  "passwordLabel": "Senha",
  "titleRequired": "Titulo obrigatório!",
  "dateRequired": "Data é obrigatório!",
  "fieldRequired": "O Campo é obrigatório!",
  "championshipManager": "Gerenciador de campeonatos",
  "accountSettings": "Configurações da conta",
  "settings": "Configurações",
  "forgotPassword": "Esqueceu sua senha?",
  "resetPassword": "Redefinir senha",
  "back": "Voltar",
  "msgEnterEmailToResetPassword": "Por favor, insira o endereço de e-mail associado à sua conta e enviaremos um link para redefinir sua senha.",
  "alreadyHaveCode": "Já tem um código?",
  "verifyToken": "Verifique o token!",
  "requestSentSuccessfully": "Solicitação enviada com sucesso!",
  "weHaveSentConfirmationEmail": "Enviamos um e-mail de confirmação para",
  "pleaseCheckYourEmail": "Por favor verifique seu email.",
  "codeSent": "Código enviado!",
  "emailNotValid": "Não foi encontrado um email válido, por favor preencha o email e envie um novo código.",
  "confirmationEmailMessage": "Enviamos um código de confirmação no seu e-mail para permitir você criar uma nova senha.",
  "notReceivedCode": "Não recebeu o código?",
  "resendCode": "Reenviar código",
  "tokenInvalid": "Código inválido",
  "neededCode": "Código é necessário",
  "verified": "Verificado com sucesso",
  "code": "Código",
  "verifyButton": "Verificar",
  "firstAccessOrValidation": "Esse é seu primeiro acesso ao Gerenciador de Campeonatos ou por algum motivo de segurança é necessário validar sua autenticação, por favor, informe seu email e clique em Continuar. Precisamos desta validação para você entrar no Gerenciador do Futstat.",
  "continue": "Continuar",
  "isItYourFirstAccess": "É o seu primeiro acesso? Clique Aqui!",
  "invalidEmail": "Email inválido!",
  "newPassword": "Nova senha",
  "confirmNewPassword": "Confirmar nova senha",
  "passwordsMustMatch": "As senhas não conferem",
  "passwordUpdatedPleaseReLogin": "Sua senha foi atualizada/criada. Por favor, coloque suas credenciais novamente!",
  "registerNewPassword": "Cadastrar nova senha",
  "requiredToAcceptTerms": "É obrigatório aceitar os termos!",
  "confirmYourRegistration": "Para seguir precisamos do seu cadastro no Futstat, levará apenas alguns minutos.",
  "status": "Status",
  "fields": {
    "status": "Status",
    "logo": "Escudo",
    "name": "Nome",
    "dateOfBirth": "Data de nascimento",
    "email": "Email",
    "phoneNumber": "Celular",
    "cpf": "CPF",
    "rg": "RG",
    "docFederacao": "Doc Federação",
    "docCbf": "Doc Cbf",
    "docCbfs": "Doc Cbfs",
    "iReadAndAgreeWithTheTerms": "Li e concordo com os termos de serviço e políticas de privacidade.",
    "inChargeEmail": "Email do responsável",
    "inChargeName": "Nome do responsável",
    "country": "País",
    "state": "Estado",
    "city": "Cidade",
    "modality": "Modalidade",
    "category": "Categoria",
    "gender": "Gênero",
    "championshipType": "Tipo de campeonato",
    "season": "Temporada",
    "championshipContext": "Contexto do campeonato",
    "showInApp": "Mostrar no aplicativo",
    "enrolPeriod": "Período de inscrição",
    "numberOfTeams": "Quantidade de equipes",
    "numberOfGroups": "Quantidade de grupos",
    "inTheClassificationPhase": "Na fase de classificação",
    "inTheEliminatoryPhase": "Na fase eliminatória",
    "numberOfTeamsPassingFromPhase": "Quantidade equipes que passam de fase",
    "inTheFinals": "Nas finais",
    "tiebreakerCriteria": "Critérios de desempate",
    "managementMode": "Modo de gestão da tabela de jogos do campeonato",
    "teamNickname": "Nome fantasia",
    "teamName": "Nome do time",
    "nickname": "Apelido",
    "code": "Código",
    "region": "Região",
    "colors": "Cores",
    "playerName": "Nome do jogador",
    "player": "Jogador",
    "position": "Posição",
    "coach": "Técnico",
    "doc": "Documento",
    "phase": "Fase",
    "groupName": "Grupo",
    "round": "Rodada",
    "date": "Data",
    "hour": "Hora",
    "homesTeam": "Time da casa",
    "regularHour": "Tempo regular",
    "stoppageTime": "Prorrogação",
    "penaltyShootouts": "Pênaltis",
    "awaysTeam": "Time visitante",
    "token": "Token",
    "place": "Local",
    "homesTeamName": "Nome do time da casa",
    "awaysTeamName": "Nome do time visitante",
    "groupNamefield": "Nome do grupo",
    "matchDate": "Data da partida",
    "gamesNumber": "Jogo",
    "undefinedTeams": "Equipes já definidas",
    "game": "Jogo",
    "gameOrder": "Jogo de",
    "dtGame": "Data do jogo",
    "hrGame": "Hora do jogo"
  },
  "saveChanges": "Salvar alterações",
  "invalidDate": "Data inválida!",
  "registrationUpdated": "Cadastro atualizado!",
  "manageCompetitions": "Gerenciar competições",
  "competitionsList": "Lista de competições",
  "chooseYourActionToManageCompetitionsAndChampionship": "Escolha a ação desejada para gerenciar suas competições e campeonatos",
  "editCompetitionConfiguration": "Editar competição",
  "selectCompetition": "Ver campeonatos",
  "seeMore": "Ver mais",
  "consultTeams": "Consultar equipes",
  "listMyCompetitions": "Listar minhas competições",
  "createNewCompetition": "Criar nova competição",
  "selectedCompetition": "Competição selecionada",
  "change": "alterar",
  "modality": "Modalidade",
  "incharge": "Responsável",
  "seeLess": "Ver menos",
  "category": "Categoria",
  "gender": "Gênero",
  "championshipType": "Tipo de campeonato",
  "season": "Temporada",
  "type": "Tipo",
  "teams": "Equipes",
  "show": "Mostrar",
  "goToNextPage": "Ir para próxima página",
  "of": "de",
  "campionshipName": "Nome do campeonato",
  "inChargeEmail": "Email do responsável",
  "phoneNumberCantBeLessThan11": "Número do celular não pode ser menor que 11 caracteres.",
  "competitions": "Competições",
  "championships": "Campeonatos",
  "manageYourChampionship": "Gerencie seus campeonatos",
  "competitionCreatedSuccessfully": "Competição criada com sucesso!",
  "competitionUpdatedSuccessfully": "Competição editada com sucesso!",
  "editCompetition": "Editar competição",
  "players": "Jogadores",
  "add": "Adicionar",
  "cancel": "Cancelar",
  "save": "Salvar",
  "saveAndEditChampionships": "Salvar e editar campeonatos",
  "createNewChampionship": "Criar campeonato",
  "editChampionship": "Editar campeonato",
  "entity": "Entidade",
  "selectTheYearThatSeasonWillFinish": "Selecione o ano que o campeonato encerra. Ex.: campeonato inicia em out. de 2024 e encerrará em jan. de 2025, entao selecione o ano 2025",
  "incorrectYearFormat": "Formato do ano incorreto. Por favor, digite o ano com 4 digitos.",
  "championshipCreatedSuccessfully": "Campeonato criado com sucesso",
  "championshipUpdatedSuccessfully": "Campeonato editado com sucesso",
  "configureAthletesRegistration": "Configure o período de inscrição dos atletas nas suas respectivas equipes de acordo a agenda da sua competição",
  "minimumQuantity": "Quantidade mínima: {{minQuantity}}",
  "acceptValues": "Valores aceitos: {{firstNumbers}} ou {{lastNumber}}",
  "maxValueForNTeamsNext": "A quantidade não pode ser maior que o número de equipes definido no campo acima.",
  "fillNumberOfTeamsField": "Preencha um valor válido no campo \"Número de equipes\"",
  "invalidNumberOfTeams": "Quantidade inválida. É preciso que haja times suficiente para que cada grupo tenha o mesmo número de times. \nEx. válido: 6 times e 2 grupos, serão 3 times por grupo \nEx. inválido: 5 times e 3 grupos, não é possivel dividir os times igualmente nos grupos",
  "System": "Sistema",
  "Manual": "Manual",
  "invalidYear": "Ano inválido. Por favor, digite o ano com 4 digitos e maior que o ano atual",
  "finishChampionship": "Finalizar campeonato",
  "championshipFinished": "Campeonato finalizado com sucesso!",
  "cancelChampionship": "Cancelar campeonato",
  "championshipCanceled": "Campeonato cancelado com sucesso!",
  "areYouSureToCancelChampionship": "Tem certeza que deseja cancelar o campeonato: {{competitionName}}?",
  "thisActionIsIrreversibleAndAfterDeletedThereIsNoReturn": "Essa é uma ação irreversível e após deletado, este campeonato deixará de existir.",
  "setEnrolPeriod": "Configurar período de inscrição",
  "setEnrolPeriodforChampionshipName": "Configurar período de inscrição para o campeonato: {{competitionName}}",
  "configureTheEnrollmentPeriod": "*Configure o período de inscrição dos atletas nas suas respectivas equipes de acordo a agenda da sua competição:",
  "enrollmentPeriodUpdated": "Período de inscrição atualizado com sucesso!",
  "myTeams": "Minhas equipes",
  "allTeams": "Todas as equipes",
  "createNewTeam": "Criar nova equipe",
  "championshipSelectedSuccessfully": "Mostrando equipes do campeonato escolhido! Para ver outras equipes, selecione outro campeonato.",
  "showTeams": "Ver equipes",
  "selectedChampionship": "Campeonato selecionado",
  "competitionSelectedSuccessfully": "Mostrando campeonatos da competição selecionada! Para ver outros campeonatos, selecione outra competição.",
  "manageYourTeams": "Gerencie suas equipes",
  "championship": "Campeonato",
  "team": "Equipe",
  "manageYourPlayers": "Gerencie seus jogadores",
  "messageSubscribePlayer": "Registrado com sucesso",
  "messageEditSubscribePlayer": "Registro editado com sucesso",
  "messageUnSubscribePlayer": "Inscrição removida com sucesso",
  "tabAllPlayers": "Jogadores inscritos",
  "tabAvailableForRegistration": "Jogadores pré-inscritos",
  "subscribeActionMenuSubscribe": "Inscrever no campeonato",
  "subscribeActionMenuEdit": "Editar inscrição",
  "subscribeActionMenuRemove": "Remover inscrição",
  "showPlayers": "Mostrar jogadores",
  "includePlayerOrCoachingStaff": "Incluir jogador e/ou comissão técnica",
  "competition": "Competição",
  "showMatches": "Mostrar tabela de jogos",
  "createNewMacth": "Criar nova partida",
  "manageYourMatches": "Gerencie suas partidas",
  "matches": "Tabela de jogos",
  "match": "Partida",
  "houseTeamCanBeEqualToAway": "O time da casa não pode ser o mesmo que o time visitante",
  "awayTeamCanBeEqualToHouse": "O time visitante não pode ser o mesmo que o time da casa",
  "editMatch": "Editar partida",
  "listMatch": "Listar partidas",
  "matchCreatedSuccessfully": "Partida criada com successo!",
  "matchUpdatedSuccessfully": "Partida atualizada com successo!",
  "approveMatch": "Aprovar súmula",
  "restoreMatch": "Restaurar súmula",
  "cancelMatch": "Cancelar partida",
  "matchApproveSuccessfully": "Súmula aprovada com sucesso",
  "matchRestoreSuccessfully": "Súmula restaurada com sucesso",
  "matchCancelSuccessfully": "Partida cancelada com sucesso",
  "automaticMatchCreation": "Criação automática da tabela de jogos do campeonato: {{competitionName}}",
  "automaticMatchCreationDescription": "Na fase de Classificação do campeonato, a definição dos cruzamentos entre as equipes é realizada por sorteio. Na fase Eliminatória, os cruzamentos são realizados de acordo com a ordem de classificação das equipes no campeonato, onde o primeiro colocado enfrenta o último colocado, e assim sucessivamente. Além disso, caso o modelo de campeonato definido seja por jogos de ida e volta, a partida de volta é decidida sempre na casa do time melhor colocado na fase de classificação.",
  "areYouSureToCreateTheMatchs": "Você deseja criar a tabela de jogos deste campeonato? Escolha sua decisão abaixo da tabela.",
  "confirm": "Confirmar",
  "theresAlreadyCreatedMatches": "Este campeonato já possui uma tabela de jogos criada! Para refazê-la, delete a tabela atual e tente novamente.",
  "matchTimeAndPlaceSuccessfully": "Hora e lugar ajustados com sucesso",
  "setTimePlace": "Definir hora e lugar",
  "setReferee": "Definir árbitro da partida",
  "setRefereeForTheMatch": "Definir árbitro da partida: {{round}} - {{houseTeam}} x {{awayTeam}}",
  "readTerms": "Ler termos",
  "matchDeletedSuccessfully": "Partida deletada com sucesso",
  "matchsDeletedSuccessfully": "Partidas deletadas com sucesso",
  "deleteMatch": "Deletar partida",
  "deleteAllMatches": "Deletar a tabela de jogos",
  "areYouSureToDeleteMatch": "Deletar a partida: {{round}} - {{houseTeam}} x {{awayTeam}}?",
  "areYouSureToExecuteThisAction": "Você realmente deseja realizar esta operação?",
  "youCanAlwaysRecreateAMatch": "Você sempre poderá recriar uma ou mais partidas ao clicar no botão \"Criar nova partida\".",
  "matchesCreatedSuccessfully": "Tabela de jogos criada",
  "thisOperationCanNotBeUnmade": "Esta operação não poderá ser desfeita!",
  "areYouSureToCancelMatch": "Cancelar a partida: {{round}} - {{houseTeam}} x {{awayTeam}}?",
  "isOnlyPossibleToEditMatchIfChampioionshipIsInConstruction": "Não é permitido alterar uma partida em um campeonato iniciado (com partidas já realizadas) ou finalizados!",
  "isNotPossibleToEditAllMatchesIfChampioionshipIsNotInConstruction": "Não é permitido alterar a tabela de jogos em um campeonatos finalizados!",
  "coachingStaff": "Comissão técnica",
  "all": "Todos",
  "invertMatch": "Inverter partida",
  "matchInvertedSuccessfully": "Partida invertida com sucesso",
  "areYouSureToInvertMatch": "Inverter a partida: {{round}} - {{houseTeam}} x {{awayTeam}}?",
  "exportChampionship": "Exportar o campeonato",
  "exportTeam": "Exportar o time",
  "addTeam": "Adicionar equipe",
  "addTeamToChampionship": "Adicionar equipes ao campeonato: {{championshipName}}",
  "teamAddedSuccessfully": "Equipe adicionada ao campeonato com sucesso",
  "remove": "Remover",
  "addTeams": "Adicionar equipes",
  "teamAlreadyAdded": "Equipe já adicionada",
  "teamRemovedSuccessfully": "Equipe removida do campeonato com sucesso",
  "removeTeam": "Remover equipe",
  "championshipIsNotInConstructionPhase": "Campeonato não esta em fase de construção!",
  "championshipIsInFinishedPhase": "Campeonato já está em fase de finalização!",
  "deleteTeam": "Remover equipe",
  "approveModalTitle": "Importante!",
  "approveModalSubtitleOne": "Ao aprovar a súmula, as estatísticas da partida/equipe/jogador e a pontuação das equipes na competição passam a valer oficialmente no sistema!",
  "approveModalSubtitleTwo": "Para confirmar esta operação, preencha abaixo as conclusões finais desta partida.",
  "approveModalSelectInputWinnerLabel": "Vencedor",
  "approveModalSelectInputWinnerValue": "Empate",
  "approveModalSelectInputIsWoLabel": "Houve WO?",
  "approveModalSelectInputIsWoValueYes": "Sim",
  "approveModalSelectInputIsWoValueNo": "Não",
  "approveModalSelectInputIsExtraTimeLabel": "Houve prorrogação?",
  "approveModalSelectInputIsExtraTimeValueYes": "Sim",
  "approveModalSelectInputIsExtraTimeValueNo": "Não",
  "approveModalActionsDescription": "Depois que você (responsável pelo campeonato) aprovar a súmula ela NÃO PODERÁ MAIS SER ALTERADA!",
  "approveModalActionButtonCancel": "Cancelar",
  "approveModalActionButtonConfirm": "Aprovar",
  "areYouSureToRemovePlayer":"Tem certeza que deseja remover o jogador do pré-cadastro?",
  "areYouSureToRemovePlayerPlural":"Tem certeza que deseja remover os jogadores do pré-cadastro?",
  "preRegistrationActionMenuRemove": "Remover do pré cadastro",
  "messageRemoveFromPreRegisterPlayer":"Jogador eliminado do pré-cadastro",
  "teamDefinitionForEachGroup": "Definição de equipes para cada grupo do campeonato:",
  "youCanSetEveryTeamForEachGroup": "Você pode definir quais serão as equipes de cada grupo. Para isso, basta selecionar o grupo, defina suas equipes e então clique em 'adicionar'. Após finalizado o processo para todos os grupos, clique em 'salvar' para persistir as alterações.",
  "youCanSortTeamsAutomatically": "Se preferir, o sistema pode sortear as equipes para você. Para isso, basta clicar em 'Sortear' e após verificar os resultados do sorteio, clique em 'salvar' para persistir as alterações.",
  "warningForManualGroupChampionship":"Na fase de Classificação do campeonato, a definição dos cruzamentos entre as equipes dentro de cada grupo é realizada por sorteio. Nesta opção você tem total controle sobre a tabela de jogos e definição dos cruzamentos entre as equipes seguindo seus próprios critérios de classificação e regulamento. Mas ATENÇÃO!! Os cruzamentos das fases de Playoffs devem ser inseridos manualmente no sistema por você, uma vez que você optou pela gestão manual do campeonato.",
  "typeAtLeast3Letters": "Digite ao menos 3 letras",
  "noOptionFound": "Nenhuma opção encontrada",
  "loading": "Carregando",
  "addedToGroup":"Adicionado ao Grupo:",
  "addOrRemoveTeamsFromGroup":"Adicionar ou remover equipes do grupo",
  "updateGroup":"Atualizar grupo",
  "selectTeamsPerGroup":"Selecione {{teamsNumber}} time(s) por grupo.",
  "teamsLimitReachedRemoveATeamIfYouNeedToChangeTheList":"Limite de times alcançado. Remova algum(ns) time(s) se precisar mudar a lista.",
  "randomizeThisGroupMatches":"Embaralhar partidas no grupo",
  "sampleOfMatchesCreationInGroup":"Exemplos de criação de partidas no grupo",
  "whenCreatingMatchesYouCanChooseBeteween2":"Ao criar partidas para um grupo, é possível escolher entre duas forma de criação: ordenado ou embaralhado:",
  "ordenedTypeExplanation":"Modo ordenado: A ordem dos times é muito importante. Na primeira rodada, obrigatoriamente o time 1 jogará contra o time 2, o time 3 jogará com o time 4, e assim por diante.",
  "randomizedTypeExplanation":"Modo embaralhado: Ao embaralhar, nao haverá uma ordem de prioridade e a primeira rodada terá os times jogando contra sim de forma aleatória.",
  "youCanChooseTeamsInAnyMode":"Em ambos os modos, você poderá escolher os times que farão parte do grupo.",
  "teamsOrdered":"Equipe 1 x Equipe 2, Equipe 3 x Equipe 4, etc",
  "teamsRandomized":"Equipe 2 x Equipe 4, Equipe 3 x Equipe 1, etc",
  "orderThisGroupMatches":"Ordenar partidas no grupo",
  "randomizeAllGroups":"Embaralhar todos os grupos",
  "cleanFields":"Limpar todos os campos",
  "randomizeAllGroupsTooltip":"Embaralhar todos os grupos, definirá de forma aleatória os times em cada grupo",
  "randomizeAllGroupsDescription":"Isso apagará a selecao de times atual para os grupos.",
  "friendlyGame":"Amistoso",
  "maxTeamNumberReached":"Número máximo de times alcançado, remova um time para adicionar um novo.",
  "qtyChampionship":"Qtd. campeonatos",
  "summary": "Súmula",
  "gameSummary": "Súmula do jogo",
  "summarySucccessfullyLoaded":"Súmula carregada com sucesso",
  "competitionGroupIsUnavailable":"O grupo de competição não está disponivel",
  "seeCompetitionGroup":"Ver grupo de competição",
  "seeSummary": "Ver súmula",
  "summaryOccurences": {
    "action": "Ação",
    "playerName" : "Jogador",
    "playerNumber" : "Nr. da Camisa",
    "playerPosition" : "Pos. de jogador",
    "periodTime" : "Periodo de partida",
    "period" : "Fase",
    "gameHour" : "Tempo",
    "scoredGoals": "GOLS MARCADOS",
    "subtitleDescription" : "(Inicia pelas ocorrencias mais recentes da partida)",
    "faultsAndCards": "FALTAS E CARTÕES APLICADOS"
  },
  "summaryApprove":{
    "title": "Importante!",
    "body1": "Ao aprovar a súmula, as estatísticas da partida / equipe / jogador e as pontuações das equipes na competição passam a ser oficialmente válidas no sistema!",
    "body2": "Depois que você (responsável pelo campeonato) aprovar a súmula, ela NÃO PODE MAIS SER ALTERADO!",
    "body3": "Para confirmar esta operação, preencha abaixo as conclusões finais desta partida.",
    "winner": "Ganhador",
    "hasOvertime": "Teve prorrogação",
    "hasWo": "Teve wo",
    "approove": "Aprovar",
    "approoved": "Aprovada",
    "classifiedTeam":"Time classificado",
    "draw":"Empate",
    "no": "Não",
    "yes": "Sim",
    "canceled": "Cancelada",
    "preApprooved": "Pré-Aprovada"
  },
  "noResultsFound":"Sem resultados encontrados",
  "createTeam":"Criar equipe",
  "teamCreated":"Equipe criada com sucesso",
  "editTeam":"Editar equipe",
  "teamEdited":"Equipe atualizada com sucesso",
  "areYouSureToFinishChampionship": "Tem certeza que deseja finalizar o campeonato: {{competitionName}}?",
  "isNotPossibleToEditMatchIfChampioionshipIsFinished": "Não é permitido alterar uma partida em um campeonato finalizados!",
  "matchAlreadyApprovedOrPreApproved":"Partida ja está pré-aprovada ou aprovada",
  "matchAlreadyApproved":"Partida ja está aprovada",
  "matchAlreadyPending":"Partida ja está pendente",
  "matchWasCanceled":"Partida foi cancelada",
  "unsubscribed": "Pré-inscritos",
  "subscribed": "Inscritos",
  "expandTable": "Expandir tabela",
  "unExpandTable": "Desespandir tabela",
  "expandTableRows": "Expandir linhas da tabela",
  "unExpandTableRows": "Desespandir linhas da tabela",
  "see": "Ver",
  "dragTheFileOr": "Arraste o arquivo ou",
  "inYourComputer": "No seu computador",
  "sendMaxFileSize": "Enviar com tamanho máximo",
  "sendFile": "Enviar arquivo",
  "editFile": "Editar arquivo",
  "colors2": "Cores",
  "noOptions": "Sem resultados...",
  "playerHasMatchOccurrences": "Jogador não pode ser desinscrito pois já foi escalado",
  "tabTechnicalCommittee": "Comissão técnica",
  "sendInvites": "Enviar convites",
  "sentInvites": "Convites enviados",
  "addOnlyEmail": "Adicionar apenas o email",
  "searchForANameToPickUpPlayersForSendingTheInvites": "Preencha o campo ao lado para escolher jogadores pra enviar os convites",
  "send": "Enviar",
  "colors": {
    "violetBlue": "Azul Violeta",
    "brightTurquoiseBlue": "Azul Turquesa Brilhante",
    "turquoiseBlue": "Azul Turquesa",
    "tokyoBlue": "Azul Tóquio",
    "taparuereBlue": "Azul Taparuere",
    "royalBlue": "Azul Royal",
    "gunpowderBlue": "Azul Pólvora",
    "tealBlue": "Azul-Verde",
    "midnightBlue": "Azul Meia-Noite",
    "mediumBlue": "Azul Médio",
    "navyBlue": "Azul Marinho",
    "butterBlue": "Azul Manteiga",
    "stealthBlue": "Azul Stealth",
    "airForceBlue": "Azul Força Aérea",
    "cornflowerBlue": "Azul Centáurea",
    "darkBlue": "Azul Escuro",
    "cobaltBlue": "Azul Cobalto",
    "lightBlue": "Azul Claro",
    "deepSkyBlue": "Azul Céu Profundo",
    "lightSkyBlue": "Azul Céu Claro",
    "skyBlue": "Azul Céu",
    "brightSkyBlue": "Azul Céu Brilhante",
    "celeste": "Celeste",
    "comradeBlue": "Azul Camarada",
    "cadetBlue": "Azul Cadete",
    "brilliantBrazilianBlue": "Azul Brasileiro Brilhante",
    "brazilianBlue": "Azul Brasileiro",
    "sandyBlue": "Azul Areia",
    "mediumSlateBlue": "Azul Ardósia Médio",
    "darkSlateBlue": "Azul Ardósia Escuro",
    "lightSlateBlue": "Azul Ardósia Claro",
    "slateBlue": "Azul Ardósia",
    "aliceBlue": "Azul Alice",
    "lightSteelBlue": "Azul Aço Claro",
    "steelBlue": "Azul Aço",
    "blue": "Azul",
    "asparagus": "Aspargo",
    "amethyst": "Ametista",
    "almond": "Amêndoa",
    "plum": "Ameixa",
    "amber": "Âmbar",
    "burntYellow": "Amarelo Queimado",
    "lightGoldYellow": "Amarelo Ouro Claro",
    "greenishYellow": "Amarelo Esverdeado",
    "darkYellow": "Amarelo Escuro",
    "creamYellow": "Amarelo Creme",
    "lightYellow": "Amarelo Claro",
    "brazilianYellow": "Amarelo Brasileiro",
    "yellow": "Amarelo",
    "alizarin": "Alizarina",
    "mediumAquamarine": "Aquamarina Média",
    "aquamarine": "Água-marinha",
    "water": "Água",
    "saffron": "Açafrão",
    "beige": "Bege",
    "burgundy": "Bordô",
    "white": "Branco",
    "antiqueWhite": "Branco Antigo",
    "ghostWhite": "Branco Fantasma",
    "floralWhite": "Branco Floral",
    "smokeWhite": "Branco Fumaça",
    "navajoWhite": "Branco Navajo",
    "brazil": "Brasil",
    "bronze": "Bronze",
    "khaki": "Caqui",
    "thistle": "Cardo",
    "crimson": "Carmesim",
    "carmine": "Carmim",
    "classicCarmine": "Carmim Clássico",
    "carnaceousCrimson": "Carmesim Carnáceo",
    "reddishBrown": "Marrom Avermelhado",
    "lightBrown": "Marrom Claro",
    "carrot": "Cenoura",
    "cherry": "Cereja",
    "hollywoodCherry": "Cereja Hollywood",
    "chocolate": "Chocolate",
    "cyan": "Ciano",
    "lightCyan": "Ciano Claro",
    "darkCyan": "Ciano Escuro",
    "gray": "Cinza",
    "slateGray": "Cinza Ardósia",
    "lightSlateGray": "Cinza Ardósia Claro",
    "darkSlateGray": "Cinza Ardósia Escuro",
    "lightGray": "Cinza Claro",
    "darkGray": "Cinza Escuro",
    "dullGray": "Cinza Fosco",
    "mediumGray": "Cinza Médio",
    "copper": "Cobre",
    "shell": "Concha",
    "coral": "Coral",
    "lightCoral": "Coral Claro",
    "leather": "Couro",
    "cream": "Creme",
    "creamShellfish": "Creme Marisco",
    "creamOfMint": "Creme de Menta",
    "darkKhaki": "Caqui Escuro",
    "dainise": "Dainise",
    "golden": "Dourado",
    "darkGold": "Dourado Escuro",
    "paleGold": "Dourado Pálido",
    "ebony": "Ébano",
    "eminence": "Eminência",
    "scarlet": "Escarlate",
    "emerald": "Esmeralda",
    "eucalyptus": "Eucalipto",
    "fandango": "Fandango",
    "feldspar": "Feldspato",
    "rust": "Ferrugem",
    "flirt": "Flerte",
    "soot": "Fuligem",
    "fuchsia": "Fúcsia",
    "maroon": "Granada",
    "gainsboro": "Gainsboro",
    "glitter": "Glitter",
    "guava": "Goiaba",
    "herbal": "Herbal",
    "heliotrope": "Heliotrópio",
    "indigo": "Índigo",
    "independence": "Independência",
    "iris": "Íris",
    "jade": "Jade",
    "jambo": "Jambo",
    "jasmine": "Jasmim",
    "kiwi": "Kiwi",
    "kobi": "Kobi",
    "kobicha": "Kobicha",
    "orange": "Laranja",
    "darkOrange": "Laranja Escuro",
    "lightOrange": "Laranja Claro",
    "lavender": "Lavanda",
    "reddishLavender": "Lavanda Avermelhada",
    "lilac": "Lilás",
    "lime": "Limão",
    "lemon": "Limão",
    "linen": "Linho",
    "wood": "Madeira",
    "magenta": "Magenta",
    "darkMagenta": "Magenta Escuro",
    "mauve": "Malva",
    "papayaBlended": "Papaya Mesclado",
    "manna": "Maná",
    "ivory": "Marfim",
    "yellowishBrown": "Marrom Amarelado",
    "pinkishBrown": "Marrom Rosado",
    "brownSaddle": "Marrom Sela",
    "corn": "Milho",
    "lightCorn": "Milho Claro",
    "moccasin": "Mocassim",
    "mustard": "Mostarda",
    "navy": "Azul Marinho",
    "snow": "Neve",
    "nyanza": "Nyanza",
    "ochre": "Ocre",
    "olive": "Oliva",
    "darkOlive": "Oliva Escura",
    "brownOlive": "Oliva Marrom",
    "orchid": "Orquídea",
    "darkOrchid": "Orquídea Escura",
    "mediumOrchid": "Orquídea Média",
    "gold": "Dourado",
    "brown": "Marrom",
    "darkBrown": "Marrom Escuro",
    "silver": "Prateado",
    "black": "Preto",
    "peach": "Pêssego",
    "purple": "Roxo",
    "mediumPurple": "Roxo Médio",
    "quantum": "Quantum",
    "quartz": "Quartzo",
    "antiqueLace": "Renda Antiga",
    "pink": "Rosa",
    "brightPink": "Rosa Brilhante",
    "lovePink": "Rosa Amor",
    "lightPink": "Rosa Claro",
    "damnPink": "Rosa Intenso",
    "dullPink": "Rosa Fosco",
    "hotPink": "Rosa Choque",
    "deepPink": "Rosa Profundo",
    "brazilianPurple": "Roxo Brasileiro",
    "rutile": "Rutilo",
    "salmon": "Salmão",
    "lightSalmon": "Salmão Claro",
    "darkSalmon": "Salmão Escuro",
    "siena": "Siena",
    "sepia": "Sépia",
    "tangerine": "Tangerina",
    "terracotta": "Terracota",
    "firebrick": "Tijolo Refratário",
    "tomato": "Tomate",
    "wheat": "Trigo",
    "triassic": "Triássico",
    "turquoise": "Turquesa",
    "darkTurquoise": "Turquesa Escura",
    "mediumTurquoise": "Turquesa Média",
    "turquoisePale": "Turquesa Pálida",
    "ube": "Ube",
    "annatto": "Urucum",
    "green": "Verde",
    "spectrumGreen": "Verde Espectral",
    "yellowishGreen": "Verde Amarelado",
    "lightGreen": "Verde Claro",
    "darkGreen": "Verde Escuro",
    "forestGreen": "Verde Floresta",
    "fluorescentGreen": "Verde Fluorescente",
    "limeGreen": "Verde Limão",
    "grassGreen": "Verde Grama",
    "lightSeaGreen": "Verde Mar Claro",
    "darkSeaGreen": "Verde Mar Escuro",
    "mediumSeaGreen": "Verde Mar Médio",
    "militaryGreen": "Verde Militar",
    "oliveGreen": "Verde Oliva",
    "parisGreen": "Verde Paris",
    "springGreen": "Verde Primavera",
    "mediumSpringGreen": "Verde Primavera Médio",
    "paleGreen": "Verde Pálido",
    "blueGreen": "Verde Azulado",
    "red": "Vermelho",
    "blackishRed": "Vermelho Enegrecido",
    "darkRed": "Vermelho Escuro",
    "redIndian": "Vermelho Índio",
    "redViolet": "Vermelho Violeta",
    "mediumRedViolet": "Vermelho Violeta Médio",
    "paleRedViolet": "Vermelho Violeta Pálido",
    "violet": "Violeta",
    "darkViolet": "Violeta Escuro",
    "lightViolet": "Violeta Claro",
    "xanadu": "Xanadu"
  },
  "positions": {
    "goalkeeper": "Goleiro",
    "winger": "Ala",
    "pivot": "Pivô",
    "fixed": "Fixo",
    "defender": "Zagueiro",
    "full-back": "Lateral",
    "defensiveMidfielder": "Volante",
    "midfielder": "Meio Campo",
    "striker": "Atacante",
    "centerForward": "Centroavante",
    "representative": "Representante",
    "doctor": "Médico",
    "coach": "Técnico",
    "physiotherapist": "Fisioterapeuta",
    "performanceAnalyst": "Analista de Desempenho",
    "masseur": "Massagista",
    "physicalTrainer": "Preparador Físico",
    "supervisor": "Supervisor",
    "technicalAssistant": "Auxiliar Técnico",
    "goalkeeperTrainer": "Preparador de Goleiro"
  },
  "editPlayer":"Editar Jogador",
  "showChampionshipId": "Mostrar id do campeonato",
  "showChampionshipIdModalMinorDescription": "NOTA: Se for necessário, contacte a equipe Futstat para mais informacão.",
  "showChampionshipIdModalDescription": "Utilize este código de identificação para consultar (via API) informacões sobre o campeonato selecionado:",
  "showChampionshipIdModalTitle": "Copiar código de identificação do campeonato?",
  "copiedCodeSuccessfully": "Código copiado com sucesso",
  "createGamesTable":"Criar tabela de jogos",
  "refereeSetSuccessfully":"Árbitro definido com sucesso!",
  "update":"Atualizar",
  "clear":"Limpar",
  "somethingWentWrongWithTheMatchUpdate": "Ocorreu um erro ao atualizar o {{game}}º jogo"
}
