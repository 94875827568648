import { useSelector } from 'react-redux';
import { Api } from '../../../apis/Api';
import { AuthReducerState } from '../../../redux/reducers/authReducer';
import { useSnackbar } from 'notistack';
import { useAuthAction } from '../../../redux/actions/authAction';
import { AxiosResponse } from 'axios';
import { ContentType } from '../../../apis/http-client';

import {
  CompetitionMatchsOverviewViewModelIEnumerableIResult,
  CompetitionOptionsViewModel,
  CompetitionTeamPlayerCriteria,
  CompetitionTeamPlayerSubscribeViewModelIQueryableIResult,
  PlayerViewModelIQueryableIResult,
  CompetitionViewModel,
  CreateCompetitionCriteria,
  Int32StringKeyValuePair,
  TeamViewModel,
  TeamViewModelIEnumerableIResult,
  UpdateCompetitionCriteria,
  V1CompetitionsListParams,
  V1CompetitionsMatchsDetailParams,
  V1CompetitionsTeamsDetailParams,
  V1CompetitionsTeamsPlayersDetailParams,
  CompetitionTeamPlayerSubscribeViewModelIResult,
  CompetitionTeamPlayerSubscribeViewModel,
  DefaultLanguageEnum,
  IActionResult,
  V1CompetitionsTeamsPlayerDetailParams,
  V1CompetitionsTechnicalCommitteeListListParams,
} from '../../../apis/data-contracts';

export const useCompetitionService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { loginAction } = useAuthAction();
  const api = new Api({
    baseURL: `${process.env.REACT_APP_COMPETITIONMANAGERAPI_BASEURL}`,
  });

  const auth = useSelector((state: { auth: AuthReducerState }) => state?.auth);
  const requestParams = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };

  return {
    listCompetition: async (
      query: V1CompetitionsListParams
    ): Promise<AxiosResponse<CompetitionViewModel[]>> => {
      return await api
        .v1CompetitionsList(query, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.validationErros)
            enqueueSnackbar(
              Array(err.response.data.validationErros).join('\n'),
              {
                variant: 'error',
                autoHideDuration: 3000, className: "snackbar" 
              }
            );

          throw err;
        });
    },

    getModalities: async (): Promise<Int32StringKeyValuePair[]> => {
      return await api
        .v1CompetitionsModalitiesList(requestParams)
        .then(async (resp) => {
          return resp?.data?.result ?? [];
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    newCompetition: async (
      query: CreateCompetitionCriteria
    ): Promise<CompetitionViewModel | undefined> => {
      return await api
        .v1CompetitionsCreate(query, requestParams)
        .then(async (resp) => {
          return resp?.data?.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.validationErros)
            enqueueSnackbar(
              Array(err.response.data.validationErros).join('\n'),
              {
                variant: 'error',
                autoHideDuration: 3000, className: "snackbar" 
              }
            );

          throw err;
        });
    },

    editCompetition: async (
      query: UpdateCompetitionCriteria
    ): Promise<CompetitionViewModel | undefined> => {
      return await api
        .v1CompetitionsUpdate(query, requestParams)
        .then(async (resp) => {
          return resp?.data?.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.validationErros)
            enqueueSnackbar(
              Array(err.response.data.validationErros).join('\n'),
              {
                variant: 'error',
                autoHideDuration: 3000, className: "snackbar" 
              }
            );

          throw err;
        });
    },

    getCompetitionById: async (
      competitionId: number
    ): Promise<CompetitionViewModel | undefined> => {
      return await api
        .v1CompetitionsDetail(competitionId, requestParams)
        .then(async (resp) => {
          return resp?.data?.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    getCompetitionOptions: async (
      type: string
    ): Promise<CompetitionOptionsViewModel | undefined> => {
      return await api
        .v1CompetitionsOpitionsDetail(type, requestParams)
        .then(async (resp) => {
          return resp?.data?.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    getCompetitionTypes: async (): Promise<string[] | undefined | null> => {
      return await api
        .v1CompetitionsTypesList(requestParams)
        .then(async (resp) => {
          return resp?.data?.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    finishCompetition: async (
      championshipId: number
    ): Promise<CompetitionViewModel | undefined | null> => {
      return await api
        .v1CompetitionsFinishUpdate(championshipId, requestParams)
        .then(async (resp) => {
          return resp?.data?.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    cancelCompetition: async (
      competitionId: number
    ): Promise<CompetitionViewModel | undefined | null> => {
      return await api
        .v1CompetitionsCancelDelete(competitionId, requestParams)
        .then(async (resp) => {
          return resp?.data?.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    updateEnrollPeriodByCompetitionId: async (
      competitionId: number,
      enrollPeriod: string
    ): Promise<CompetitionViewModel | undefined | null> => {
      return await api
        .v1CompetitionsSetEnrollPeriodUpdate(
          competitionId,
          enrollPeriod,
          requestParams
        )
        .then(async (resp) => {
          return resp?.data?.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    listTeamsByCompetitionId: async (
      query: V1CompetitionsTeamsDetailParams
    ): Promise<AxiosResponse<TeamViewModelIEnumerableIResult>> => {
      return await api
        .v1CompetitionsTeamsDetail(query, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    addTeamByCompetitionId: async (
      competitionId: number,
      teamId: number
    ): Promise<TeamViewModel | undefined> => {
      return await api
        .v1CompetitionsTeamsAddCreate(competitionId, teamId, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    removeTeamByTeamId: async (
      competitionId: number,
      teamId: number
    ): Promise<TeamViewModel | undefined> => {
      return await api
        .v1CompetitionsTeamsDelete(competitionId, teamId, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    listPlayers: async (
      query: V1CompetitionsTeamsPlayersDetailParams
    ): Promise<
      AxiosResponse<CompetitionTeamPlayerSubscribeViewModelIQueryableIResult>
    > => {
      return await api
        .v1CompetitionsTeamsPlayersDetail(query, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    listTechnicalCommittee: async (
      query: V1CompetitionsTechnicalCommitteeListListParams
    ): Promise<
      AxiosResponse<CompetitionTeamPlayerSubscribeViewModelIQueryableIResult>
    > => {
      return await api
        .v1CompetitionsTechnicalCommitteeListList(query, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    listMatches: async (
      query: V1CompetitionsMatchsDetailParams
    ): Promise<
      AxiosResponse<CompetitionMatchsOverviewViewModelIEnumerableIResult>
    > => {
      return await api
        .v1CompetitionsMatchsDetail(query, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    deleteAllMatches: async (
      competitionId: number
    ): Promise<CompetitionViewModel[] | null | undefined> => {
      return await api
        .v1CompetitionsDeleteAllMatchesDelete(competitionId, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },
    competitionsTeamsPlayersExport: async (
      competitionId: number,
      language: DefaultLanguageEnum
    ): Promise<IActionResult | any> => {
      return await api
        .v1CompetitionsTeamsPlayersExportDetail2(
          { id: competitionId, language: language },
          requestParams
        )
        .then(async (resp) => {
          return resp.data;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });
          throw err;
        });
    },
    competitionsTeamsPlayersByTeamExport: async (
      competitionId: number,
      teamId: number,
      language: DefaultLanguageEnum
    ): Promise<IActionResult | any> => {
      return await api
        .v1CompetitionsTeamsPlayersExportDetail(
          { id: competitionId, teamId: teamId, language: language },
          requestParams
        )
        .then(async (resp) => {
          return resp.data;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });
          throw err;
        });
    },

    listPlayersOnCompetition: async (
      id: number,
      teamId: number
    ): Promise<AxiosResponse<PlayerViewModelIQueryableIResult>> => {
      return await api
        .v1CompetitionsTeamsPlayersToSubscribeDetail(id, teamId, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    registerPlayerFromCompetition: async (
      data: CompetitionTeamPlayerCriteria
    ): Promise<TeamViewModel | undefined> => {
      return await api
        .v1PlayersRegisterForCompetitionCreate(data, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    editRegisterPlayerFromCompetition: async (
      data: CompetitionTeamPlayerCriteria
    ): Promise<TeamViewModel | undefined> => {
      return await api
        .v1PlayersEditRegisterInCompetitionCreate(data, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    removePlayerFromCompetition: async (
      playerId: number,
      competitionId: number
    ): Promise<CompetitionTeamPlayerSubscribeViewModel | undefined> => {
      return await api
        .v1PlayersRemoveFromCompetitionDelete(
          playerId,
          competitionId,
          requestParams
        )
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },
    
    removeFromPreRegistration: async (
      playerId: number,
      competitionId: number
    ): Promise<CompetitionTeamPlayerSubscribeViewModel | undefined> => {
      return await api
        .v1PlayersRemoveFromPreRegistrationDelete(
          playerId,
          competitionId,
          requestParams
        )
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });
          throw err;
        });
    },
    getCompetitionTeamPlayerById: async (body: V1CompetitionsTeamsPlayerDetailParams): Promise<CompetitionTeamPlayerSubscribeViewModelIResult> => {
      return await api.v1CompetitionsTeamsPlayerDetail(body, requestParams)
        .then(async (resp) => {
            return resp.data;
        })
        .catch(async (err) => {
          if(err?.response?.data?.message)
              enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar"
              });
          throw err;
      })
    }



  };
};
